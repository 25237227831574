<script>
import Swal from "sweetalert2";

export default {
  methods: {
    is_arr(arr) {
      return arr != null && arr.constructor === Array;
    },
    is_str(str) {
      return str && /string/.test(typeof str);
    },
    is_func(func) {
      return func != null && func.constructor === Function;
    },
    is_num(num) {
      return num != null && !isNaN(num);
    },
    is_int(x) {
      const y = parseInt(x);
      if (isNaN(y)) return false;
      return x === y && x.toString() === y.toString();
    },
    is_obj(obj) {
      return obj != null && obj instanceof Object;
    },
    is_ele(ele) {
      return ele && ele.tagName && ele.nodeType === 1;
    },
    is_exists(obj) {
      return obj != null && true && obj !== "undefined";
    },
    is_blank(str) {
      return this.util_trim(str) === "";
    },
    is_phone(num) {
      return /^(01([0-9]{2})|09[0-9]|08[0-9]|07[0-9]|03[0-9])(\d{7})$/i.test(
        num
      );
    },
    is_email(str) {
      // eslint-disable-next-line no-useless-escape
      return /^[a-z-_0-9\.]+@[a-z-_=>0-9\.]+\.[a-z]{2,3}$/i.test(
        this.util_trim(str)
      );
    },
    is_username(value) {
      return (
        value.match(/^[0-9]/) == null && value.search(/^[0-9_a-zA-Z]*$/) > -1
      );
    },
    is_link(str) {
      // eslint-disable-next-line no-useless-escape
      return /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(
        this.util_trim(str)
      );
    },
    is_image(imagePath) {
      const fileType = imagePath
        .substring(imagePath.lastIndexOf("."), imagePath.length)
        .toLowerCase();
      return (
        fileType === ".gif" ||
        fileType === ".jpg" ||
        fileType === ".png" ||
        fileType === ".jpeg"
      );
    },
    util_trim(str) {
      return /string/.test(typeof str) ? str.replace(/^\s+|\s+$/g, "") : "";
    },
    showLoading(isShow) {
      this.$bus.$emit("show-loading", isShow);
    },
    nConfirm(title, description, icon, callback, callbackData) {
      let buildinIcon = ["warning", "error", "success", "info", "question"];
      if (buildinIcon.indexOf(icon) < 0) {
        icon = "question";
      }
      title = title ? title : "";
      if (this.is_blank(title)) {
        title = "Bạn có chắc chắn?";
      }
      description = description ? description : "";
      if (this.is_blank(description)) {
        description = "You want to do this!";
      }
      Swal.fire({
        title: title,
        text: description,
        icon: icon,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tiếp tục",
        cancelButtonText: "Huỷ bỏ",
      }).then((result) => {
        if (result.isConfirmed) {
          if (callback) {
            if (callbackData) {
              callback(callbackData);
            } else {
              callback();
            }
          }
        }
      });
    },
    nAlert(msg, icon, cb, cbData) {
      let buildinIcon = ["warning", "error", "success", "info", "question"],
        title = "";
      if (buildinIcon.indexOf(icon) < 0) {
        icon = "info";
      }
      msg = msg ? msg : "";
      if (!this.is_blank(msg)) {
        switch (icon) {
          case "error":
            title = "Đã có lỗi xảy ra";
            break;
          case "success":
          case "warning":
          case "question":
            title = msg;
            msg = "";
            break;
          default:
            title = "Thông báo";
        }
        Swal.fire({
          title: title,
          text: msg,
          icon: icon,
        }).then(function () {
          if (cb) {
            if (cbData) {
              cb(cbData);
            } else {
              cb();
            }
          }
        });
      }
    },
  },
};
</script>
