<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Thêm mới user</h6>
        </template>
        <b-row>
          <b-col>
            <b-form-group label="Email">
              <b-form-input
                v-model="input.email"
                placeholder="Email"
                type="email"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col> </b-col>
        </b-row>
      </b-card>
    </b-card-group>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Chọn vai trò</h6>
        </template>
        <b-row>
          <b-col sm="12">
            <b-form-checkbox
              :value="true"
              v-model="allSelected"
              :unchecked-value="false"
              @change="toggleAll()"
              size="lg"
            >
              <b>Chọn tất cả</b>
            </b-form-checkbox>
          </b-col>
          <b-col sm="12">
            <b-form-group class="container">
              <template v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  size="lg"
                  class="row"
                  id="flavors"
                  v-model="input.role_id"
                  :aria-describedby="ariaDescribedby"
                  stacked
                >
                  <b-form-checkbox
                    size="lg"
                    class="col-sm-2"
                    v-for="(item, index) in options.roles"
                    :key="index"
                    :value="item.id"
                    inline
                    >{{ item.name }}</b-form-checkbox
                  >
                </b-form-checkbox-group>
              </template>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group>
              <b-form-checkbox
                id="checkbox-1"
                v-model="input.is_active"
                v-bind:value="1"
                v-bind:unchecked-value="0"
              >
                Kích hoạt
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <hr />
        </b-row>
        <b-row>
          <b-col class="text-center">
            <b-button class="mr-2" variant="outline-primary" @click="store()"
              >Lưu
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import Common from "../../../core/mixins/common";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Helper from "../../../core/mixins/helper";
const CmsRepository = RepositoryFactory.get("cms");

export default {
  mixins: [Common, Helper],
  data() {
    return {
      input: {
        email: null,
        is_active: null,
        role_id: [],
      },
      options: {
        roles: [],
      },
      allSelected: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý User", route: "user" },
      { title: "Thêm User", route: "add-user" },
    ]);
  },
  methods: {
    async store() {
      if (this.input.email.slice(-8) !== "@9pay.vn") {
        alert("Email không được phép truy cập!");
        return;
      }
      let params = this.input;
      CmsRepository.addUser(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            return false;
          } else {
            this.notifyAlert("success", response.data.message);
            return this.$router.push({ name: "list-user" });
          }
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
    },
    listAllRole() {
      CmsRepository.listAllRoles()
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            return false;
          } else {
            this.options.roles = response.data.data;
            this.notifyAlert("success", response.data.message);
          }
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
    },
    toggleAll() {
      if (this.allSelected) {
        this.input.role_id = this.options.roles.map((item) => item.id);
      } else {
        this.input.role_id = [];
      }
    },
  },
  watch: {
    "input.role_id"() {
      let roleAll = this.options.roles.map((item) => item.id);
      if (roleAll.length == this.input.role_id.length) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
    },
  },
  created() {
    this.listAllRole();
  },
};
</script>
<style lang="scss" scoped>
.ch-gr-relative {
  position: relative;
}
.ch-icon-eye {
  position: absolute;
  right: 10px;
  top: 33px;
  cursor: pointer;
}
.ch-margin-left {
  margin-left: 10px;
}
</style>
